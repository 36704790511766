import React from 'react';
import MobileScreen from '../Assets/App-Dev.png'; // Use your image path here

const WebsiteMaintenanceWhyPartnerWithUs = () => {
  return (
    <div className="bg-white py-12">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-bold mb-8">Benefits of Choosing Us</h2>
        <div className="flex flex-col lg:flex-row justify-center items-center lg:space-x-16">
          <div className="lg:w-1/3 mb-8 lg:mb-0">
            <h3 className="text-xl font-bold mb-4">Expert Team</h3>
            <p className="text-gray-600 mb-8">
            Our team of experienced web developers and designers are experts in their fields. They stay updated with the latest trends and technologies to ensure your website is always running smoothly and efficiently.
            </p>
            <h3 className="text-xl font-bold mb-4">Affordable Plans</h3>
            <p className="text-gray-600">
            We offer competitive pricing and flexible plans to fit any budget. You get top-notch web maintenance services without breaking the bank.
            </p>
          </div>
          <div className="mb-8 lg:mb-0">
            <img src={MobileScreen} alt="Mobile Screen" className="w-96 h-auto mx-auto" />
          </div>
          <div className="lg:w-1/3">
            <h3 className="text-xl font-bold mb-4">Customized Solutions</h3>
            <p className="text-gray-600 mb-8">
            We know that every business is unique. That’s why we offer customized web maintenance plans that cater to your specific requirements, ensuring your website always reflects your brand and serves your goals.
            </p>
            <h3 className="text-xl font-bold mb-4">Transparency</h3>
            <p className="text-gray-600">
            We believe in clear and open communication. You’ll receive regular reports on your website’s performance, updates, and any issues resolved, keeping you informed every step of the way.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteMaintenanceWhyPartnerWithUs;
