import React from 'react';
import Logodesign1 from '../Assets/Logodesign1.png';

const LogoDesignAbout = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-between bg-white py-8 pl-8">
      <div className="md:w-1/2 pr-8">
        <h2 className="text-2xl font-bold text-black mb-4">
        Do you need an amazing logo design?       </h2>
        <p className="text-base text-gray-600 mb-4">
        Getting a new logo will assist establish your brand in the industry, regardless of whether you are just starting out or looking to update your current visual identity. We will integrate your branding onto your website as soon as you are satisfied with your newly created logo so that your clients will know who you are.
        </p>
        <p className="text-base text-gray-600 mb-4">
        For just $395, you can get a professionally designed logo with your new website. Give us a call and let us know what we can accomplish for you.
        </p>
        <button className="mt-5 bg-yellow-500 text-white py-2 px-4 rounded">
          Read More
        </button>
      </div>
      <div className="mt-8 md:mt-0 md:w-1/2 flex justify-center">
        <img 
          src={Logodesign1} 
          alt="Marketing Strategies" 
          className="w-full h-1/4 object-contain" 
        />
      </div>
    </div>
  );
};

export default LogoDesignAbout;
