import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Assets/ZoneServices.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faMapMarkerAlt, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
  const hoverTimeoutRef = useRef(null);

  const toggleServicesDropdown = (isOpen) => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }

    if (isOpen) {
      setIsServicesDropdownOpen(true);
    } else {
      hoverTimeoutRef.current = setTimeout(() => {
        setIsServicesDropdownOpen(false);
      }, 70); // 70ms = 0.7 seconds
    }
  };

  return (
    <>
      {/* Top Bar */}
      <div className="w-full bg-transparent flex justify-between items-center p-2 shadow-md ">
        {/* Social Media Icons */}
        <div className="flex items-center space-x-3 ">
          <a href="#" className="text-black bg-yellow-500 rounded-full h-6 w-6">
            <FontAwesomeIcon icon={faFacebookF} className="flex size-4 p-1" />
          </a>
          <a href="#" className="text-black bg-yellow-500 rounded-full h-6 w-6">
            <FontAwesomeIcon icon={faInstagram} className="flex size-4 p-1" />
          </a>
          <a href="#" className="text-black bg-yellow-500 rounded-full h-6 w-6">
            <FontAwesomeIcon icon={faLinkedinIn} className="flex size-4 p-1" />
          </a>
        </div>

        {/* Contact Information */}
        <div className="flex items-center space-x-6 text-gray-700 text-sm">
          <div className="flex items-center space-x-2">
            <span className="text-yellow-500 ">
              <FontAwesomeIcon icon={faEnvelope} className="h-5 w-5 " />
            </span>
            <span>Info@zoneservices.com.au</span>
          </div>
          <div className="flex items-center space-x-2">
            <span className="text-yellow-500">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="h-5 w-5" />
            </span>
            <span>Sydney 2000</span>
          </div>
        </div>
      </div>

      {/* Header */}
      <header className="w-full top-0 left-0 z-50 bg-transparent flex justify-between items-center p-4 border-t-2 border-black">
        <div className="flex items-center">
          <Link to="/">
          <img src={Logo} alt="Logo" className="h-24 rounded-full " />
          </Link>
        </div>
        <div className="flex items-center">
          <nav className="ml-10 relative">
            <ul className="flex justify-between space-x-6 text-blue-800 font-sans font-medium text-md ">
              <li><Link to="/About" className="hover:underline">About</Link></li>
              <li
                className="relative"
                onMouseEnter={() => toggleServicesDropdown(true)}
                onMouseLeave={() => toggleServicesDropdown(false)}
              >
                <button className="hover:underline flex items-center">
                  Services <FontAwesomeIcon icon={faChevronDown} className="ml-1" />
                </button>
                {isServicesDropdownOpen && (
                  <ul className="absolute top-full left-0 mt-2 w-48 bg-white shadow-lg rounded-md py-2 z-20">
                    <li><Link to="/web-design-development" className="block px-4 py-2 hover:bg-gray-100">Web Design Development</Link></li>
                    <li><Link to="/digital-marketing" className="block px-4 py-2 hover:bg-gray-100">Digital Marketing Service</Link></li>
                    <li><Link to="/application-development" className="block px-4 py-2 hover:bg-gray-100">Application Development</Link></li>
                    <li><Link to="/website-maintenance" className="block px-4 py-2 hover:bg-gray-100">Website Maintenance</Link></li>
                    <li><Link to="/logo-design" className="block px-4 py-2 hover:bg-gray-100">Logo Design</Link></li>
                    <li><Link to="/web-hosting" className="block px-4 py-2 hover:bg-gray-100">Web Hosting</Link></li>
                    <li><Link to="/e-commerce" className="block px-4 py-2 hover:bg-gray-100">E-commerce</Link></li>
                  </ul>
                )}
              </li>
              <li><Link to="/portfolio" className="hover:underline">Portfolio</Link></li> 
              <li><Link to="/Pricing" className="hover:underline">Pricing</Link></li>
              <li><Link to="/Contact" className="hover:underline">Contact Us</Link></li>
            </ul>
          </nav>
        </div>
        <div className="flex items-center space-x-4">
          <a href="tel:0483 964 322" className="font-medium font-sans text-gray-900 bg-yellow-500 p-2 rounded-md hover:bg-yellow-600">
          0483 964 322
          </a>
        </div>
      </header>
    </>
  );
};

export default Header;
