import React from 'react';

const PromoSection = () => {
  return (
    <div className="bg-gray-50 py-6 px-4 sm:py-12 sm:px-6 lg:px-8">
      <div className="text-center">
        <h2 className="text-3xl font-bold text-gray-900 leading-tight">
          Your Gateway to Global Excellence.
        </h2>
        <p className="mt-4 text-3xl font-bold text-gray-900 leading-tight">
          Australia’s Premier Web Design & Development Agency.
        </p>
      </div>
    </div>
  );
};

export default PromoSection;
