import React from 'react';

const DigitalMarketingServices = () => {
  const services = [
    {
      title: "Search Engine Optimization (SEO)",
      description: "Enhance your website's visibility and achieve higher search engine rankings with our advanced SEO strategies. Our expert team uses cutting-edge techniques to drive organic traffic to your site, leading to increased conversions and sales."
    },
    {
      title: "Pay-Per-Click Advertising (PPC)",
      description: "Achieve maximum ROI with our precise PPC campaigns. We create and manage targeted ads that reach your ideal customers, driving immediate traffic and generating high-quality leads."
    },
    {
      title: "Social Media Marketing",
      description: "Grow and engage your audience on platforms like Facebook, Instagram, LinkedIn, and Twitter. Our social media strategies are designed to build brand awareness, foster customer loyalty, and drive sales."
    },
    {
      title: "Content Marketing",
      description: "In the digital realm, content is king. Our team of expert writers creates compelling content that resonates with your audience, enhances your brand's authority, and boosts your SEO efforts."
    },
    {
      title: "Email Marketing",
      description: "Convert leads into loyal customers with our personalized email marketing campaigns. We craft captivating messages that keep your audience engaged and encourage them to take action."
    },
    {
      title: "Web Design and Development",
      description: "Your website is your digital storefront. We design and develop visually stunning, user-friendly websites that provide a seamless experience for your visitors and drive conversions."
    },
    {
      title: "Analytics and Reporting",
      description: "Make informed decisions with our comprehensive analytics and reporting services. We provide insights into your campaign's performance, helping you understand what works and where to improve."
    },
  ];

  return (
    <div className="bg-gray-900 text-white py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Digital Marketing Services we provide</h2>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {services.map((service, index) => (
            <div key={index} className="bg-gray-800 p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">{service.title}</h3>
              <p className="text-gray-300">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DigitalMarketingServices;
