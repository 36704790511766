import React from 'react';
import videoBackground from '../Assets/contact.mp4'; // Replace with the correct path to your video file

const LogoDesignBanner = () => {
  return (
    <div className="relative w-full h-96 overflow-hidden align-middle">
      {/* Background Video */}
      <video
        className="absolute top-0 left-0 w-full h-full object-cover "
        src={videoBackground}
        autoPlay
        loop
        muted
      />

      {/* Overlay */}
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-70 "></div>

      {/* Content */}
      <div className="relative items-center justify-center text-center h-full content-center space-y-4">
        <h1 className="text-5xl font-bold text-white text-center">Looking for a Standout Logo Design?
        Get Started with just $395</h1>
        <div className="flex justify-center">
          <p className="text-center text-white w-1/2">
          We’ll help you establish your business’s presence and connect with your audience with our branding and logo design services. Our team will create a professional identity that not only looks visually stunning, but also supports the growth and recognisability of your business.
          </p>
        </div>

        <button
          type="submit"
          className="mt-4 bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-6 rounded"
        >
          Let's Connect
        </button>
      </div>
    </div>
  );
};

export default LogoDesignBanner;
