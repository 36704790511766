import React from 'react';
import ApplicationDevelopmentBanner from '../components/ApplicationDevelopmentBanner';
import ApplicationDevelopmentSection from '../components/ApplicationDevelopmentSection';
import ApplicationDevelopmentSec from '../components/ApplicationDevelopmentSec';
import ApplicationDvelopmentWhyPartnerWithUs from '../components/ApplicationDvelopmentWhyPartnerWithUs';
import TestimonialsCarousel from '../components/TestimonialsCarousel'
import FormSection from '../components/DigitalMarketingFormSection'



const ApplicationDevelopment = () => {
    return (
      <div>
        <ApplicationDevelopmentBanner />
        <ApplicationDevelopmentSection />
        <ApplicationDevelopmentSec />
        <ApplicationDvelopmentWhyPartnerWithUs />
        <TestimonialsCarousel />
        <FormSection />

        


  
      </div>
    );
  };
  
  export default ApplicationDevelopment;