import React from 'react';
import videoBackground from '../Assets/contact.mp4'; // Replace with the correct path to your video file

const ApplicationDevelopmentBanner = () => {
  return (
    <div className="relative w-full h-96 overflow-hidden align-middle">
      {/* Background Video */}
      <video
        className="absolute top-0 left-0 w-full h-full object-cover "
        src={videoBackground}
        autoPlay
        loop
        muted
      />

      {/* Overlay */}
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-70 "></div>

      {/* Content */}
      <div className="relative items-center justify-center text-center h-full content-center space-y-4">
        <h1 className="text-5xl font-bold text-white text-center">Application Development Company for iOS, Android and Web Apps in Australia</h1>
        <div className="flex justify-center">
          <p className="text-center text-white w-1/2">
          Start your app journey journey with affordable solutions and expert consultation.Our professional developers, who have years of experience and have produced numerous apps, provide Australian businesses and startup entrepreneurs with excellent mobile app solutions. With our experience, turn your industry into the latest gold rush.
          </p>
        </div>

        <button
          type="submit"
          className="mt-4 bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-6 rounded"
        >
          Get Started With us
        </button>
      </div>
    </div>
  );
};

export default ApplicationDevelopmentBanner;
