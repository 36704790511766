import React from 'react';
import MobileScreen from '../Assets/App-Dev.png'; // Use your image path here

const WhyPartnerWithUs = () => {
  return (
    <div className="bg-white py-12">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-bold mb-8">Why partner with us?</h2>
        <div className="flex flex-col lg:flex-row justify-center items-center lg:space-x-16">
          <div className="lg:w-1/3 mb-8 lg:mb-0">
            <h3 className="text-xl font-bold mb-4">Expert Team</h3>
            <p className="text-gray-600 mb-8">
              Our digital marketing professionals bring years of experience and a passion for driving results.
            </p>
            <h3 className="text-xl font-bold mb-4">Proven Results</h3>
            <p className="text-gray-600">
              We have a track record of delivering successful campaigns that increase traffic, engagement, and sales.
            </p>
          </div>
          <div className="mb-8 lg:mb-0">
            <img src={MobileScreen} alt="Mobile Screen" className="w-96 h-auto mx-auto" />
          </div>
          <div className="lg:w-1/3">
            <h3 className="text-xl font-bold mb-4">Customized Strategies</h3>
            <p className="text-gray-600 mb-8">
              We understand that every business is unique. Our solutions are tailored to your specific goals and difficulties.
            </p>
            <h3 className="text-xl font-bold mb-4">Transparent Reporting</h3>
            <p className="text-gray-600">
              We believe in complete transparency. You'll receive regular updates and detailed reports on your campaign’s performance.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyPartnerWithUs;
