import React from 'react';

const PricingCard = ({ title, price, features, buttonLabel }) => {
  return (
    <div className="border border-gray-200 rounded-lg p-6 text-center shadow-md hover:shadow-lg transition-shadow duration-300">
      <h3 className="text-2xl font-bold text-gray-800 mb-4">{title}</h3>
      <p className="text-4xl font-bold text-gray-800 mb-6">{price}</p>
      <ul className="text-gray-700 mb-8 space-y-2">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center justify-center space-x-2">
            <span>✔</span>
            <span>{feature}</span>
          </li>
        ))}
      </ul>
      <button className="bg-transparent border-2 border-yellow-500 text-yellow-500 font-semibold py-2 px-4 rounded hover:bg-yellow-500 hover:text-white transition-colors duration-300">
        {buttonLabel}
      </button>
    </div>
  );
};

export default PricingCard;
