import React from 'react';
import WebDevelopment from '../Assets/Web-Development.png';


const WebDesginDivSec = () => {
  return (
    <div className="bg-white py-12 px-4 sm:px-6 lg:py-16 lg:px-8 flex justify-between items-center  lg:mx-8">
    <div className="lg:w-8/12">
      <p className="mt-4 text-lg leading-6 text-gray-500">
        Welcome to our web design company, serving your small, medium, and large-scale needs for web design and development services throughout Australia. At the core, we specialize in stunning and user-friendly websites custom-made according to the nature of your business. From Melbourne to Brisbane, Adelaide, and Sydney’s outskirts, we are here to grant you top-of-the-line web solutions.
      </p>
      <p className="mt-4 text-lg leading-6 text-gray-500">
        Welcome to our web design company, serving your small, medium, and large-scale needs for web design and development services throughout Australia. At the core, we specialize in stunning and user-friendly websites custom-made according to the nature of your business. From Melbourne to Brisbane, Adelaide, and Sydney’s outskirts, we are here to grant you top-of-the-line web solutions.
      </p>
    </div>
  
    <div className="lg:w-4/12 flex-shrink-0">
      <div className="relative">
        <img
          className="w-full object-cover rounded-md"
          src={WebDevelopment}
          alt="Office workspace"
        />
      </div>
    </div>
  </div>
  
  );
};

export default WebDesginDivSec;
