import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Ecommerce from '../Assets/SHOPIFY.jpg';
import Webdev from '../Assets/WEB-DESIGN.jpg';
import Searchengopt from '../Assets/SEARCH-ENGINE-OPTIMIZATION.jpg';
import Animation from '../Assets/ANIMATIONS.jpg';
import Appdev from '../Assets/APPLICATION-DEVELOPMENT.png';
import Webhost from '../Assets/WEB-HOSTING.jpg';
import Webmaintainance from '../Assets/WEBSITE-MAINTENANCE.jpg';
import Logodesign from '../Assets/LOGO-DESIGN.jpg';

const ServiceCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container mx-auto py-8">
      <Slider {...settings}>
        <div className="p-4 text-center transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
          <img
            src={Ecommerce}
            alt="Ecommerce"
            className="mx-auto mb-4 w-32 h-32 object-contain rounded-full transition-transform transform hover:rotate-6"
          />
          <h3 className="text-xl font-bold mb-2 text-gray-800">Ecommerce</h3>
          <p className="text-gray-600">
            Discover the comprehensive suite of e-commerce solutions with Shopify. From building your online store to managing inventory, payments etc.
          </p>
        </div>
        <div className="p-4 text-center transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
          <img
            src={Webdev}
            alt="Web Development & Design"
            className="mx-auto mb-4 w-32 h-32 object-contain rounded-full transition-transform transform hover:rotate-6"
          />
          <h3 className="text-xl font-bold mb-2 text-gray-800">Web Development & Design</h3>
          <p className="text-gray-600">
            We develop websites crafted for your brand, featuring personalized web designs and seamless Shopify integration.
          </p>
        </div>
        <div className="p-4 text-center transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
          <img
            src={Searchengopt}
            alt="Digital Marketing Service"
            className="mx-auto mb-4 w-32 h-32 object-contain rounded-full transition-transform transform hover:rotate-6"
          />
          <h3 className="text-xl font-bold mb-2 text-gray-800">Digital Marketing Service</h3>
          <p className="text-gray-600">
            We optimize your website with targeted keywords, expert on-page and off-page optimization strategies, and comprehensive analytics.
          </p>
        </div>
        <div className="p-4 text-center transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
          <img
            src={Animation}
            alt="Animation"
            className="mx-auto mb-4 w-32 h-32 object-contain rounded-full transition-transform transform hover:rotate-6"
          />
          <h3 className="text-xl font-bold mb-2 text-gray-800">Animation</h3>
          <p className="text-gray-600">
            We create captivating 2D and 3D animations, motion graphics, and character animations tailored to your brand.
          </p>
        </div>
        <div className="p-4 text-center transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
          <img
            src={Appdev}
            alt="Application Development"
            className="mx-auto mb-4 w-32 h-32 object-contain rounded-full transition-transform transform hover:rotate-6"
          />
          <h3 className="text-xl font-bold mb-2 text-gray-800">Application Development</h3>
          <p className="text-gray-600">
            Transform your vision into a powerful, user-friendly application with our expert team.
          </p>
        </div>
        <div className="p-4 text-center transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
          <img
            src={Webmaintainance}
            alt="Website Maintenance"
            className="mx-auto mb-4 w-32 h-32 object-contain rounded-full transition-transform transform hover:rotate-6"
          />
          <h3 className="text-xl font-bold mb-2 text-gray-800">Website Maintenance</h3>
          <p className="text-gray-600">
            Regular updates and security checks ensure your website runs smoothly and securely.
          </p>
        </div>
        <div className="p-4 text-center transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
          <img
            src={Webhost}
            alt="Web Hosting"
            className="mx-auto mb-4 w-32 h-32 object-contain rounded-full transition-transform transform hover:rotate-6"
          />
          <h3 className="text-xl font-bold mb-2 text-gray-800">Web Hosting</h3>
          <p className="text-gray-600">
            Ensure your website is always accessible with our reliable and secure web hosting services.
          </p>
        </div>
        <div className="p-4 text-center transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
          <img
            src={Logodesign}
            alt="Logo Design"
            className="mx-auto mb-4 w-32 h-32 object-contain rounded-full transition-transform transform hover:rotate-6"
          />
          <h3 className="text-xl font-bold mb-2 text-gray-800">Logo Design</h3>
          <p className="text-gray-600">
            A unique, professional logo captures your business’s essence, enhancing visibility and customer loyalty.
          </p>
        </div>
      </Slider>
    </div>
  );
};

export default ServiceCarousel;
