import React from 'react';
import Instagrampost from '../Assets/Webhosting.png'

const WebHostingAbout = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-between bg-white py-8 pl-8">
      <div className="md:w-1/2 pr-8">
        <h2 className="text-2xl font-bold text-black mb-4">
        Web Hosting Services in Australia        </h2>
        <p className="text-base text-gray-600 mb-4">
        Zone Services provides a dependable and expandable web hosting option for Australian companies and developers looking to establish a strong online presence. Our strategically located data center in Sydney helps to low latency access, providing Australian users to access websites and applications efficiently.
        </p>
        <p className="text-base text-gray-600 mb-4">
        Web hosting services cover a range of hosting solutions, such as managed dedicated hosting, virtual private servers (VPS), shared hosting, and reseller hosting. Whether you’re managing an advanced mobile application or building a personal website, this versatility lets you select the hosting plan that best fits your unique requirements.
        </p>
        <p className="text-base text-gray-600 mb-4">
        Zone Services web hosting solutions give you the performance, dependability, and scalability you need to thrive in the Australian digital landscape, whether you’re a local company selling to the Australian market or a global company growing into Au.
        </p>
        <button className="mt-5 bg-yellow-500 text-white py-2 px-4 rounded">
          Our Services
        </button>
      </div>
      <div className="mt-8 md:mt-0 md:w-1/2 flex justify-center">
        <img 
          src={Instagrampost} 
          alt="Marketing Strategies" 
          className="w-full h-1/4 object-contain" 
        />
      </div>
    </div>
  );
};

export default WebHostingAbout;
