import React from 'react';
import Aboutpic from '../Assets/About-pic.jpg';
import { RiH5 } from 'react-icons/ri';

const AboutSec = () => {
    return (
        <div className="bg-white flex flex-row justify-evenly align-center ">

            <div className="relative flex align-middle">
                <img
                    className="h-72 w-full object-cover rounded-md "
                    src={Aboutpic}
                    alt="Office workspace"
                />
            </div>

            <div className='max-w-3xl '>

            <div className="py-5 ">

                <h3 className="text-3xl font-extrabold text-gray-900 sm:text-4xl text-center py-5">
                    Our Core Values
                </h3>
                <div className=' flex flex-row justify-evenly'>

                    <div className=''>
                       
                        <div className='flex justify-center'>
                            <div className="bg-yellow-500 p-4 rounded-full text-white r">
                                    <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                                        {/* Location Icon */}
                                        <path d="M12 2C8.1 2 5 5.1 5 9c0 1.4.4 2.7 1.2 3.8L12 22l5.8-9.2c.8-1.1 1.2-2.4 1.2-3.8 0-3.9-3.1-7-7-7zm0 9.5c-1.4 0-2.5-1.1-2.5-2.5S10.6 6.5 12 6.5s2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5z" />
                                    </svg>
                                </div>
                        </div>

                        <h5 className='className="mt-4 text-lg leading-6 text-gray-500 text-center'>Innovation</h5>
                            <p className="mt-4 text-lg leading-6 text-gray-500 text-center">
                                Zone Services isn’t just another web development company.
                            </p>

                    </div>

                    <div className=''>
                       
                        <div className='flex justify-center'>
                            <div className="bg-yellow-500 p-4 rounded-full text-white r">
                                    <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                                        {/* Location Icon */}
                                        <path d="M12 2C8.1 2 5 5.1 5 9c0 1.4.4 2.7 1.2 3.8L12 22l5.8-9.2c.8-1.1 1.2-2.4 1.2-3.8 0-3.9-3.1-7-7-7zm0 9.5c-1.4 0-2.5-1.1-2.5-2.5S10.6 6.5 12 6.5s2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5z" />
                                    </svg>
                                </div>
                        </div>

                        <h5 className='className="mt-4 text-lg leading-6 text-gray-500 text-center'>Innovation</h5>
                            <p className="mt-4 text-lg leading-6 text-gray-500 text-center">
                                Zone Services isn’t just another web development company.
                            </p>

                    </div>





                    {/** 
                <ul className="mt-4 space-y-2">
                    <li className="flex items-center">
                        <span className="text-yellow-500 font-bold mr-2">✓</span> Long-Term Partnership
                    </li>
                    <li className="flex items-center">
                        <span className="text-yellow-500 font-bold mr-2">✓</span> Data-Driven Approach
                    </li>
                    <li className="flex items-center">
                        <span className="text-yellow-500 font-bold mr-2">✓</span> Hosting Heroes
                    </li>
                    <li className="flex items-center">
                        <span className="text-yellow-500 font-bold mr-2">✓</span> Ecommerce Experts
                    </li>
                    <li className="flex items-center">
                        <span className="text-yellow-500 font-bold mr-2">✓</span> SEO Savvy
                    </li>
                </ul>
                */}

                </div>


            </div>

            <div className="py-5 ">

                <div className=' flex flex-row justify-evenly'>

                    <div className=''>
                       
                        <div className='flex justify-center'>
                            <div className="bg-yellow-500 p-4 rounded-full text-white r">
                                    <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                                        {/* Location Icon */}
                                        <path d="M12 2C8.1 2 5 5.1 5 9c0 1.4.4 2.7 1.2 3.8L12 22l5.8-9.2c.8-1.1 1.2-2.4 1.2-3.8 0-3.9-3.1-7-7-7zm0 9.5c-1.4 0-2.5-1.1-2.5-2.5S10.6 6.5 12 6.5s2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5z" />
                                    </svg>
                                </div>
                        </div>

                        <h5 className='className="mt-4 text-lg leading-6 text-gray-500 text-center'>Innovation</h5>
                            <p className="mt-4 text-lg leading-6 text-gray-500 text-center">
                                Zone Services isn’t just another web development company.
                            </p>

                    </div>
                    <div className=''>
                       
                        <div className='flex justify-center'>
                            <div className="bg-yellow-500 p-4 rounded-full text-white r">
                                    <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                                        {/* Location Icon */}
                                        <path d="M12 2C8.1 2 5 5.1 5 9c0 1.4.4 2.7 1.2 3.8L12 22l5.8-9.2c.8-1.1 1.2-2.4 1.2-3.8 0-3.9-3.1-7-7-7zm0 9.5c-1.4 0-2.5-1.1-2.5-2.5S10.6 6.5 12 6.5s2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5z" />
                                    </svg>
                                </div>
                        </div>

                        <h5 className='className="mt-4 text-lg leading-6 text-gray-500 text-center'>Innovation</h5>
                            <p className="mt-4 text-lg leading-6 text-gray-500 text-center">
                                Zone Services isn’t just another web development company.
                            </p>

                    </div>





                    {/** 
                <ul className="mt-4 space-y-2">
                    <li className="flex items-center">
                        <span className="text-yellow-500 font-bold mr-2">✓</span> Long-Term Partnership
                    </li>
                    <li className="flex items-center">
                        <span className="text-yellow-500 font-bold mr-2">✓</span> Data-Driven Approach
                    </li>
                    <li className="flex items-center">
                        <span className="text-yellow-500 font-bold mr-2">✓</span> Hosting Heroes
                    </li>
                    <li className="flex items-center">
                        <span className="text-yellow-500 font-bold mr-2">✓</span> Ecommerce Experts
                    </li>
                    <li className="flex items-center">
                        <span className="text-yellow-500 font-bold mr-2">✓</span> SEO Savvy
                    </li>
                </ul>
                */}

                </div>


            </div>

            </div>

        </div>
    );
};

export default AboutSec;
