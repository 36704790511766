import React from 'react';
import ApplicationDevelopment2 from '../Assets/ApplicationDevelopment2.png';

const ApplicationDevelopmentSec = () => {
    return (
        <div className="bg-white py-12 px-4 sm:px-6 lg:py-16 lg:px-8 flex justify-between items-center space-x-3">


            <div className="ml-10 flex-shrink-0 w-4/12">
                <div className="relative">
                    <img
                        className="h-full w-full object-cover rounded-md"
                        src={ApplicationDevelopment2}
                        alt="Office workspace"
                    />
                </div>
            </div>

            <div className="max-w-3xl w-8/12 px-10">

                <h3 className="text-2xl font-bold text-black mb-4">

                Zone Services Follows Best App Development Process
                </h3>

                <p className="mt-4 text-lg leading-6 text-gray-500">
                Discovery & Planning: Our journey begins with understanding your vision. We take the time to listen and learn about your goals, target audience, and unique requirements. This thorough discovery phase lays the foundation for a well-structured plan.
                </p>
                <p className="mt-4 text-lg leading-6 text-gray-500">
                Design & Prototyping: Next, we bring your ideas to life with stunning designs and interactive prototypes. Our designers craft user-friendly interfaces that are both beautiful and functional. We focus on creating an intuitive user experience that delights your users.
                </p>
                <p className="mt-4 text-lg leading-6 text-gray-500">
                Mobile Application Development: Our skilled developers then turn these designs into reality. Using the latest technologies and best practices, we build robust and scalable applications. Our development process is transparent, and we keep you informed every step of the way.
                </p>
                <p className="mt-4 text-lg leading-6 text-gray-500">
                    Quality Assurance: Before launching, we rigorously test your app to ensure it meets the highest standards. Our quality assurance team checks for bugs, performance issues, and usability problems to guarantee a flawless user experience.
                </p>

            </div>

            


        </div>
        
    );
};

export default ApplicationDevelopmentSec;