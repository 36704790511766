import React from 'react';

import { ECommerceWPU } from '../Assets';

const ECommerceWhyPartnerWithUs = () => {
  return (
    <div className="bg-white py-12">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-bold mb-8">Our ecommerce web design process</h2>
        <div className="flex flex-col lg:flex-row justify-center items-center lg:space-x-16">
          <div className="lg:w-1/3 mb-8 lg:mb-0">
            <h3 className="text-xl font-bold mb-4">Analysis</h3>
            <p className="text-gray-600 mb-8">
            we delve deep into understanding your business needs, target audience, and market trends. We conduct thorough research and gather valuable insights to create a solid foundation for your e-commerce website.
            </p>
            <h3 className="text-xl font-bold mb-4">Design and Development</h3>
            <p className="text-gray-600">
            Our team of designers and developers bring the prototype to life, crafting a visually stunning and highly functional ecommerce website. We focus on user experience, responsive design, and seamless navigation to provide an optimal shopping experience.
            </p>
          </div>
          <div className="mb-8 lg:mb-0">
            <img src={ECommerceWPU} alt="Mobile Screen" className="w-96 h-auto mx-auto" />
          </div>
          <div className="lg:w-1/3">
            <h3 className="text-xl font-bold mb-4">The Prototype</h3>
            <p className="text-gray-600 mb-8">
            We create a visual blueprint of your website, presenting a preliminary model to showcase the structure and layout. This prototype allows for early feedback and ensures alignment with your vision before moving forward.
            </p>
            <h3 className="text-xl font-bold mb-4">Testing</h3>
            <p className="text-gray-600">
            Rigorous testing is conducted to identify and rectify any issues. We perform functionality, compatibility, and performance tests to ensure your website operates smoothly across all devices and browsers.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ECommerceWhyPartnerWithUs;
