import React from 'react';
import Banner from '../Assets/Banner.jpg';

const Mainbanner = () => {
  return (
    <div
      className="relative bg-cover bg-top bg-no-repeat sm:py-24 sm:px-6 lg:px-8 lg:pt-40 -mt-40 -z-50"
      style={{ backgroundImage: `url(${Banner})` }}
    >
      <div className="text-center p-8  ">
        <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
          AUSTRALIA'S BEST WEB DESIGN AGENCY
        </h1>
        <p className="mt-4 text-lg leading-6 text-gray-600">
          Empower Your Business With Zone Services's Advanced Web Solutions
        </p>

        <div className="mt-96 ">
          <a
            href="#start-your-project"
            className="inline-block bg-yellow-500 text-white font-semibold py-3 px-6 rounded-full shadow-md hover:bg-yellow-600"
          >
            Start Your Project
          </a>
        </div>
      </div>
    </div>
  );
};

export default Mainbanner;
