import React from 'react';
import ApplicationDevelopment from '../Assets/ApplicationDevelopment.png'

const ApplicationDevelopmentSection = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-between bg-white py-8 pl-8">
      <div className="md:w-1/2 pr-8">
        <h2 className="text-2xl font-bold text-black mb-4">
          Grow Your Brand with Advanced Digital Marketing Strategies
        </h2>
        <p className="text-base text-gray-600 mb-4">
        We develop up-class mobile applications for iOS with seamless and innovative solutions. Based in Australia, we serve various industries with the finest application development software. We specialize in user-friendly and highly functional mobile application websites as well. Working out of the Gold Coast, our team ensures vigilance in the making of each app with precision and creativity to deliver experiences digitally. Be it a sleek iOS App or a robust Mobile Application, we are here to give your vision a reality with our leading-edge development services.
        </p>
        <button className="mt-5 bg-yellow-500 text-white py-2 px-4 rounded">
          Our Services
        </button>
      </div>
      <div className="mt-8 md:mt-0 md:w-1/2 flex justify-center">
        <img 
          src={ApplicationDevelopment} 
          alt="Marketing Strategies" 
          className="w-full h-1/4 object-contain" 
        />
      </div>
    </div>
  );
};

export default ApplicationDevelopmentSection;
