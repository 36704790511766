import React from 'react';
import Instagrampost from '../Assets/Instagram-post.png'

const DigitalMarketingSection = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-between bg-white py-8 pl-8">
      <div className="md:w-1/2 pr-8">
        <h2 className="text-2xl font-bold text-black mb-4">
          Grow Your Brand with Advanced Digital Marketing Strategies
        </h2>
        <p className="text-base text-gray-600 mb-4">
          Success in business today depends on having a strong online presence in the fast-paced digital environment.
          Our specialty at Zone Services is providing outstanding digital marketing services that are specifically
          designed to meet the demands of Australian companies. Our comprehensive range of services ensures your brand
          stands out in the competitive online marketplace.
        </p>
        <button className="mt-5 bg-yellow-500 text-white py-2 px-4 rounded">
          Our Services
        </button>
      </div>
      <div className="mt-8 md:mt-0 md:w-1/2 flex justify-center">
        <img 
          src={Instagrampost} 
          alt="Marketing Strategies" 
          className="w-full h-1/4 object-contain" 
        />
      </div>
    </div>
  );
};

export default DigitalMarketingSection;
