import React from 'react';
import Banner from '../components/AboutBanner';
import AboutSection from '../components/AboutSection';
import AboutSec from '../components/AboutSec';
import TestimonialsCarousel from '../components/TestimonialsCarousel';
import BrandsCarousel from '../components/BrandsCarousel';


const About = () => {
    return (
      <div>
        <Banner />
        <AboutSection />
        <AboutSec />
        <TestimonialsCarousel />
        <BrandsCarousel />

  
      </div>
    );
  };
  
  export default About;