import React, { useState } from 'react';
import TabComponent from './TabComponent';
import CardComponent from './CardComponent';
import { ClothingP1, HomeRevovation1, Transportation1 } from '../Assets';

const projects = [
  {
    title: 'Clothing Shop',
    category: 'Shopify',
    imageSrc: ClothingP1,
    link: 'https://westernwhimsey.com/',
  },
  {
    title: 'Transportation',
    category: 'PhP',
    imageSrc: Transportation1,
    link: 'https://www.skyvans.com.au/',
  },
  {
    title: 'Home Renovations',
    category: 'WordPress',
    imageSrc: HomeRevovation1,
    link: 'https://nextlevelprojectsqld.com.au/',
  },
  
  // Add more projects for other categories...
];

const categories = ['WordPress', 'Shopify', 'Animation', 'Graphic Design', 'Marketing','PhP'];

const GalleryComponent = () => {
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);

  const filteredProjects = projects.filter(
    (project) => project.category === selectedCategory
  );

  return (
    <div className="bg-[#0f101d] py-16  mx-auto">
      <TabComponent
        categories={categories}
        selectedCategory={selectedCategory}
        onSelectCategory={setSelectedCategory}
      />
      <div className="flex flex-wrap justify-center">
        {filteredProjects.map((project, index) => (
          <CardComponent
            key={index}
            title={project.title}
            category={project.category}
            imageSrc={project.imageSrc}
            link={project.link}
          />
        ))}
      </div>
    </div>
  );
};

export default GalleryComponent;
