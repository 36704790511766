import React, { useState } from 'react';

const CardComponent = ({ imageSrc, title, category, link }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className="">
      



     
      <div
      className="relative bg-transparent rounded-lg overflow-hidden transition-transform transform hover:scale-105 duration-300 ease-in-out m-2"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="overflow-hidden h-80 relative">
        <img
          src={imageSrc}
          alt={title}
          className={`w-full h-full object-cover transition-transform duration-500 ease-in-out ${
            isHovered ? 'transform -translate-y-2' : 'transform translate-y-0'
          }`}
        />
      </div>
      <div className="p-4 text-center bg-[#0f101d]">
        <h3 className="text-xl font-bold text-white mb-1">{title}</h3>
        <p className="text-sm text-gray-400">{category}</p>
      </div>
    </div>
     
      <div className="px-6 pt-4 pb-2">
        <a href={link} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          View Project
        </a>
      </div>
    </div>
  );
};

export default CardComponent;
