import React from 'react';

const DigitalMarketingFormSection = () => {
  return (
    <div className="bg-sky-950 text-white py-12">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Business?</h2>
        <p className="text-lg mb-8">
          Don’t let your competitors get ahead. Partner with Zone Services and watch your business grow. 
          Contact us today to schedule a free consultation and discover how we can help you achieve your digital marketing goals.
        </p>
        <div className="flex flex-col lg:flex-row lg:justify-center space-y-4 lg:space-y-0 lg:space-x-4">
          <input 
            type="text" 
            placeholder="First Name" 
            className="w-full lg:w-1/4 p-3 rounded text-gray-800"
          />
          <input 
            type="text" 
            placeholder="Last Name" 
            className="w-full lg:w-1/4 p-3 rounded text-gray-800"
          />
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-center space-y-4 lg:space-y-0 lg:space-x-4 mt-4">
          <input 
            type="email" 
            placeholder="Email Address" 
            className="w-full lg:w-1/4 p-3 rounded text-gray-800"
          />
          <input 
            type="text" 
            placeholder="Phone Number" 
            className="w-full lg:w-1/4 p-3 rounded text-gray-800"
          />
        </div>
        <button className="mt-8 bg-yellow-500 text-white py-3 px-6 rounded">
          Send Message
        </button>
      </div>
    </div>
  );
};

export default DigitalMarketingFormSection;
