import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Layout from './components/Layout';
import Pricing from './components/Pricing';
import Contact from './components/Contact';
import About from './components/About';
import WebDesignDev from './components/WebDesignDev';
import DigitalMarketing from './components/DigitalMarketing';
import ApplicationDevelopment from './components/ApplicationDevelopment';
import WebHosting from './components/WebHosting';
import WebsiteMaintenance from './components/WebsiteMaintenance';
import LogoDesign from './components/LogoDesign';
import ECommerce from './components/ECommerce';
import Portfolio from './components/GalleryComponent';



function App() {
  // Set the basename dynamically
  const basename = process.env.NODE_ENV === 'development' ? '/' : '/';

  return (
    <Router basename={basename}>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Pricing" element={<Pricing />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/About" element={<About />} />
          <Route path="/web-design-development" element={<WebDesignDev />} />
          <Route path="/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/application-development" element={<ApplicationDevelopment />} />
          <Route path="/web-hosting" element={<WebHosting />} />
          <Route path="/website-maintenance" element={<WebsiteMaintenance />} />
          <Route path="/logo-design" element={<LogoDesign />} />
          <Route path="/e-commerce" element={<ECommerce />} />
          <Route path="/portfolio" element={<Portfolio />} />

        



        </Routes>
      </Layout>
    </Router>
  );
}

export default App;