import React from 'react';
import MobileScreen from '../Assets/App-Dev.png'; // Use your image path here

const ApplicationDvelopmentWhyPartnerWithUs = () => {
  return (
    <div className="bg-white py-12">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-bold mb-8">Why Choose Zone Services to Digitize Your Product?</h2>
        <div className="flex flex-col lg:flex-row justify-center items-center lg:space-x-16">
          <div className="lg:w-1/3 mb-8 lg:mb-0">
            <h3 className="text-xl font-bold mb-4">Your Success Is Our Top Priority</h3>
            <p className="text-gray-600 mb-8">
            Our goal is for you to succeed. We collaborate closely with you to fully understand your objectives and make sure our solutions not only fulfill but also exceed your expectations.
            </p>
            <h3 className="text-xl font-bold mb-4">We Prioritize Humans First</h3>
            <p className="text-gray-600">
            We are committed to developing technology that benefits people. We design and develop our goods with the end user in mind because we prioritize the needs of people.
            </p>
          </div>
          <div className="mb-8 lg:mb-0">
            <img src={MobileScreen} alt="Mobile Screen" className="w-96 h-auto mx-auto" />
          </div>
          <div className="lg:w-1/3">
            <h3 className="text-xl font-bold mb-4">We Offer Constant Innovation</h3>
            <p className="text-gray-600 mb-8">
            In this modern digital age, creativity is essential. We consistently investigate new technologies and approaches in order to stay ahead of the curve.
            </p>
            <h3 className="text-xl font-bold mb-4">We Work with Ethics and Integrity</h3>
            <p className="text-gray-600">
            Our business is built on the principles of ethics and integrity. We value open communication, integrity in business dealings, and keeping our word.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationDvelopmentWhyPartnerWithUs;
