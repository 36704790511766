import React from 'react';
import { Ecommercepic } from '../Assets';

const ECommerceAbout = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-between bg-white py-8 pl-8">
      <div className="md:w-1/2 pr-8">
        <h2 className="text-2xl font-bold text-black mb-4">
        Ecommerce website with one simple goal    </h2>
        <p className="text-base text-gray-600 mb-4">
        Transformation. We are willing to give you control if your goal is for your e-commerce website to make sales. You can depend on us at every stage of the process, regardless of whether this is your first website or you want to revamp the one you have currently.
        </p>
        <p className="text-base text-gray-600 mb-4">
        We are aware of the huge stakes. You can tell that we have more than years of expertise designing e-commerce websites in Australia by looking at our track record of prioritizing conversions while still delivering great shopping experiences.
        </p>
        <button className="mt-5 bg-yellow-500 text-white py-2 px-4 rounded">
          Our Services
        </button>
      </div>
      <div className="mt-8 md:mt-0 md:w-1/2 flex justify-center">
        <img 
          src={Ecommercepic} 
          alt="Marketing Strategies" 
          className="w-full h-1/4 object-contain" 
        />
      </div>
    </div>
  );
};

export default ECommerceAbout;
