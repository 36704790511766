import React from 'react';
import MobileScreen from '../Assets/App-Dev.png'; // Use your image path here

const WebhostingWhyPartnerWithUs = () => {
  return (
    <div className="bg-white py-12">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-bold mb-8">Why partner with us?</h2>
        <div className="flex flex-col lg:flex-row justify-center items-center lg:space-x-16">
          <div className="lg:w-1/3 mb-8 lg:mb-0">
            <h3 className="text-xl font-bold mb-4">All Australian Support</h3>
            <p className="text-gray-600 mb-8">
            Enjoy dedicated support from a local team who understands your needs and provides personalized assistance.
            </p>
            <h3 className="text-xl font-bold mb-4">Amazing Customer Service</h3>
            <p className="text-gray-600">
            Our commitment to exceptional customer service ensures your issues are resolved promptly and professionally.
            </p>
          </div>
          <div className="mb-8 lg:mb-0">
            <img src={MobileScreen} alt="Mobile Screen" className="w-96 h-auto mx-auto" />
          </div>
          <div className="lg:w-1/3">
            <h3 className="text-xl font-bold mb-4">Independently Owned</h3>
            <p className="text-gray-600 mb-8">
            We are proudly independent, which means we prioritize your satisfaction over corporate interests.
            </p>
            <h3 className="text-xl font-bold mb-4">Firewall & Malware Scans</h3>
            <p className="text-gray-600">
            We prioritize your website’s security with robust firewall protection and regular malware scans to keep threats at bay.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebhostingWhyPartnerWithUs;
