import React from 'react';

const LogoDesignServices = () => {
  const services = [
    {
      title: "Helping You Profit from Great Design",
      description: "At Zone Services, we create logos that don’t just look good, but also drive business success. Our designs are crafted to enhance your brand’s visibility, attract more customers, and ultimately boost your profitability."
    },
    {
      title: "Rebranding for a Refreshed Look",
      description: "Is your brand feeling outdated? Our rebranding services will give your business a fresh, modern look. We’ll redesign your logo to better reflect your current values, appeal to your target audience, and stand out in a competitive market."
    },
    {
      title: "Simple, Effective Logo Design",
      description: "Simplicity is key to effective logo design. We focus on creating clean, memorable logos that communicate your brand’s message clearly and leave a lasting impression on your audience."
    },
    {
      title: "100% Money-Back Logo Design Guarantee",
      description: "We’re confident you’ll love your new logo. If you’re not completely satisfied with the final design, we offer a 100% money-back guarantee. Your satisfaction is our top priority."
    },
    
  ];

  return (
    <div className="bg-gray-900 text-white py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Digital Marketing Services we provide</h2>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {services.map((service, index) => (
            <div key={index} className="bg-gray-800 p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">{service.title}</h3>
              <p className="text-gray-300">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LogoDesignServices;
