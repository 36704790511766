import React from 'react';

const ContactSection = () => {
    return (
        <div className="py-16 bg-white">
            <div className="container mx-auto flex flex-col md:flex-row gap-8 items-start">
                {/* Contact Information */}
                <div className="space-y-8 flex-1">
                    <h2 className="text-3xl font-bold text-gray-800 border-l-4 border-yellow-500 pl-4">Feel Free To Contact!</h2>
                    <p className="text-gray-600 pl-4">
                        We would love to hear from you about your next web design or development project and help make it a success.
                    </p>
                    <div className="space-y-4 p-4 border-2 border-gray-300 rounded-md">
                        <div className="flex items-center space-x-4 ">
                            <div className="bg-yellow-500 p-4 rounded-full text-white">
                                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                                    {/* Location Icon */}
                                    <path d="M12 2C8.1 2 5 5.1 5 9c0 1.4.4 2.7 1.2 3.8L12 22l5.8-9.2c.8-1.1 1.2-2.4 1.2-3.8 0-3.9-3.1-7-7-7zm0 9.5c-1.4 0-2.5-1.1-2.5-2.5S10.6 6.5 12 6.5s2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5z" />
                                </svg>
                            </div>
                            <div>
                                <p className="font-semibold text-gray-800">Sydney 2000</p>
                            </div>
                        </div>
                    </div>
                    <div className="space-y-4 p-4 border-2 border-gray-300 rounded-md">
                        <div className="flex items-center space-x-4 ">
                            <div className="bg-yellow-500 p-4 rounded-full text-white">
                                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                                    {/* Location Icon */}
                                    <path d="M12 2C8.1 2 5 5.1 5 9c0 1.4.4 2.7 1.2 3.8L12 22l5.8-9.2c.8-1.1 1.2-2.4 1.2-3.8 0-3.9-3.1-7-7-7zm0 9.5c-1.4 0-2.5-1.1-2.5-2.5S10.6 6.5 12 6.5s2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5z" />
                                </svg>
                            </div>
                            <div>
                                <p className="font-semibold text-gray-800">0483 964 322</p>
                            </div>
                        </div>
                    </div>
                    <div className="space-y-4 p-4 border-2 border-gray-300 rounded-md">
                        <div className="flex items-center space-x-4 ">
                            <div className="bg-yellow-500 p-4 rounded-full text-white">
                                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                                    {/* Location Icon */}
                                    <path d="M12 2C8.1 2 5 5.1 5 9c0 1.4.4 2.7 1.2 3.8L12 22l5.8-9.2c.8-1.1 1.2-2.4 1.2-3.8 0-3.9-3.1-7-7-7zm0 9.5c-1.4 0-2.5-1.1-2.5-2.5S10.6 6.5 12 6.5s2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5z" />
                                </svg>
                            </div>
                            <div>
                                <p className="font-semibold text-gray-800">Info@zoneservices.com.au

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space-y-8 flex-1">
                    <h2 className="text-3xl font-bold text-gray-800 border-l-4 border-yellow-500 pl-4">Let's talk about your project.</h2>
                    <p className="text-gray-600 pl-4">
                        Now that you’re familiar with us, how can Zone Services help you kickstart, improve, or finalize your website?
                    </p>
                    <div className="space-y-4 pl-4">
                        <form className="space-y-6">
                            <div>
                                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="full-name">
                                    Enter Your Full Name
                                </label>
                                <input
                                    type="text"
                                    id="full-name"
                                    className="w-full border-b-2 border-yellow-500 focus:border-yellow-600 outline-none py-2"
                                    placeholder="Enter Your Full Name"
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="contact-number">
                                    Contact Number
                                </label>
                                <input
                                    type="tel"
                                    id="contact-number"
                                    className="w-full border-b-2 border-yellow-500 focus:border-yellow-600 outline-none py-2"
                                    placeholder="Contact Number"
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="email">
                                    Enter Your Email Address
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    className="w-full border-b-2 border-yellow-500 focus:border-yellow-600 outline-none py-2"
                                    placeholder="example@domain.com"
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="referral">
                                    How You Heard About Us?
                                </label>
                                <select
                                    id="referral"
                                    className="w-full border-b-2 border-yellow-500 focus:border-yellow-600 outline-none py-2 bg-transparent"
                                >
                                    <option value="">Select any service</option>
                                    {/* Add more options as needed */}
                                    <option value="google">Google</option>
                                    <option value="friend">Friend</option>
                                </select>
                            </div>

                            <div>
                                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requirements">
                                    Tell Us Briefly About Your Business, And Website Requirements
                                </label>
                                <textarea
                                    id="requirements"
                                    className="w-full border-b-2 border-yellow-500 focus:border-yellow-600 outline-none py-2"
                                    rows="3"
                                    placeholder="Describe your project..."
                                ></textarea>
                            </div>

                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="mt-4 bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-6 rounded"
                                >
                                    Let's Talk
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactSection;
