import React from 'react';
import { Aboutpic } from '../Assets';



const AboutSection = () => {
  return (
    <div className="bg-white py-12 px-4 sm:px-6 lg:py-16 lg:px-8 flex justify-between items-center">
      <div className="max-w-3xl">
        <h2 className="text-base text-yellow-500 font-semibold tracking-wide uppercase">About Us</h2>
        <h3 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
          We Craft Websites That Drive Results
        </h3>
        <p className="mt-4 text-lg leading-6 text-gray-500">
          Zone Services isn’t just another web development company. We’re a team of passionate creators and digital strategists dedicated to building websites that not only look stunning, but also achieve your business goals.
        </p>
        <ul className="mt-4 space-y-2">
          <li className="flex items-center">
            <span className="text-yellow-500 font-bold mr-2">✓</span> Long-Term Partnership
          </li>
          <li className="flex items-center">
            <span className="text-yellow-500 font-bold mr-2">✓</span> Data-Driven Approach
          </li>
          <li className="flex items-center">
            <span className="text-yellow-500 font-bold mr-2">✓</span> Hosting Heroes
          </li>
          <li className="flex items-center">
            <span className="text-yellow-500 font-bold mr-2">✓</span> Ecommerce Experts
          </li>
          <li className="flex items-center">
            <span className="text-yellow-500 font-bold mr-2">✓</span> SEO Savvy
          </li>
        </ul>
      </div>

      <div className="ml-10 flex-shrink-0">
        <div className="relative">
          <img
            className="h-72 w-full object-cover rounded-md"
            src={Aboutpic}
            alt="Office workspace"
          />
          <div className="absolute top-0 left-0 bg-yellow-500 text-white font-bold p-2 rounded-md">
            10+ YEARS OF EXPERIENCE
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
