import React from 'react';
import Banner from './EcommerceBanner';
import About from './EcommerceAbout';
import EcommerceSec from './EcommerceSec';
import WhyPartnerWithUs from './ECommerceWhyPartnerWithUs';
import TestimonialsCarousel from './TestimonialsCarousel';
import FormSection from './DigitalMarketingFormSection';


const Ecommerce = () => {
  return (
    <div>
      <Banner />
      <About />
      <EcommerceSec />
      <WhyPartnerWithUs />
      <TestimonialsCarousel />
      <FormSection />


    </div>
  );
};

export default Ecommerce;
