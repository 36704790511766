import React from 'react';

const TabComponent = ({ categories, selectedCategory, onSelectCategory }) => {
  return (
    <div className="flex justify-center my-4">
      {categories.map((category, index) => (
        <button
          key={index}
          onClick={() => onSelectCategory(category)}
          className={`px-6 py-2 mx-2 text-sm font-bold rounded-t-md ${
            selectedCategory === category
              ? 'bg-yellow-400 text-white'
              : 'bg-gray-200 text-black'
          } ${
            index === categories.length - 1
              ? 'mr-0'
              : 'border-r-2 border-gray-300'
          }`}
        >
          {category.toUpperCase()}
        </button>
      ))}
    </div>
  );
};

export default TabComponent;
