import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const TestimonialsCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    arrows: true,
    prevArrow: <button className="text-gray-600 text-2xl">{'<'}</button>,
    nextArrow: <button className="text-gray-600 text-2xl">{'>'}</button>,
  };

  const testimonials = [
    {
      text: "Zone Services has been a reliable partner in our digital journey. Their team of hosting heroes ensures our website is always up and running smoothly. We appreciate their ongoing support and commitment to our success.",
      author: "Marinda",
      role: "Intrendz Fashion",
    },
    {
      text: "We highly recommend Zone Services for their outstanding web development services. They created a website that not only looks great but also drives results. Their long-term partnership approach and data-driven strategies have been invaluable to our growth.",
      author: "William",
      role: "Guru Mortgage",
    },
    // Add more testimonials as needed
  ];

  return (
    <div className="bg-white py-16">
      <div className="container mx-auto text-center">
        <p className="text-orange-500 font-semibold">CLIENT'S TESTIMONIAL</p>
        <h2 className="text-3xl font-bold text-gray-900 mb-4">Why People Love Us</h2>
        <div className="w-20 h-1 bg-orange-500 mx-auto mb-12"></div>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="p-4">
              <div className="bg-gray-100 p-8 rounded-lg shadow-lg">
                <p className="text-lg italic text-gray-700 mb-4">“{testimonial.text}”</p>
                <h3 className="font-bold text-gray-900">{testimonial.author}</h3>
                <p className="text-sm text-gray-500">{testimonial.role}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TestimonialsCarousel;
