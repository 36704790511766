import React from 'react';
import Logodesign2 from '../Assets/Logodesign2.png';

const LogoDesignSec = () => {
    return (
        <div className="bg-white py-12 px-4 sm:px-6 lg:py-16 lg:px-8 flex justify-between items-center space-x-3">


            <div className="ml-10 flex-shrink-0 w-5/12">
                <div className="relative">
                    <img
                        className="h-full w-full object-cover rounded-md"
                        src={Logodesign2}
                        alt="Office workspace"
                    />
                </div>
            </div>

            <div className="max-w-3xl w-7/12">

                <h3 className="text-2xl font-bold text-black mb-4">
                
Our Logo Design Process
                </h3>
                <p className="text-base text-gray-600 mb-4">
                <strong>Placing Your Order:</strong> Place your order and complete the brief form so we can start working on your logo and realizing your idea.
                </p>
                <p className="text-base text-gray-600 mb-4">
                <strong>Receiving Your Concepts:</strong>  We create six different logo concepts, which we provide to you for approval. We then make unlimited changes and revise the logo based on your feedback.
                </p>
                <p className="text-base text-gray-600 mb-4">
                <strong>Receiving Your Final Logo</strong> Receiving Your Final Logo: Once you are happy with your logo,We email you your fresh new logo in a variety of file sizes and formats as soon as you let us know that it meets your needs. Even the original source files are sent to you.
                </p>           

            </div>


        </div>
    );
};

export default LogoDesignSec;
