import React from 'react';
import Logodesign2 from '../Assets/Logodesign2.png';
import { EcommerceSecPic } from '../Assets';

const LogoDesignSec = () => {
    return (
        <div className="bg-white py-12 px-4 sm:px-6 lg:py-16 lg:px-8 flex justify-between items-center space-x-3">


            <div className="ml-10 flex-shrink-0 w-5/12">
                <div className="relative">
                    <img
                        className="h-full w-full object-cover rounded-md"
                        src={EcommerceSecPic}
                        alt="Office workspace"
                    />
                </div>
            </div>

            <div className="max-w-3xl w-7/12">

                <h3 className="text-2xl font-bold text-black mb-4">
                
                Get all the advanced features that will drive your e-commerce store to new heights.
                </h3>
                <p className="text-base text-gray-600 mb-4">
                <strong> Responsive design:</strong>  In a time when prioritizing mobile traffic above desktop traffic, we make sure your website adapts to different screen sizes so it is accessible and easy to use on all devices.
                </p>
                <p className="text-base text-gray-600 mb-4">
                <strong>Content management system:</strong>   Experience the possibilities of our redesigned Content Management System interface, which is intended to manage the products, content, advertising strategies, and unique features of your website.
                </p>
                <p className="text-base text-gray-600 mb-4">
                <strong>Advanced payment gateways: </strong> Expand your online sales approach by implementing payment solutions that ensure quick and safe checkouts, a variety of payment options, and the ease of handling payments in different currencies.
                </p>           
                <p className="text-base text-gray-600 mb-4">
                <strong>Multi-channel integration: </strong>  Our e-commerce solutions make sure that your products and inventory are synchronized across all channels with a smooth connection across numerous sales channels like Amazon, eBay, or social media markets.
                </p>           

            </div>


        </div>
    );
};

export default LogoDesignSec;
