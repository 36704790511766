import React from 'react';
import Banner from '../components/PricingBanner';
import Section from '../components/PricingSection';

const Pricing = () => {
    return (
      <div>
        <Banner />
        <Section/>
  
      </div>
    );
  };
  
  export default Pricing;