import React from 'react';
import ComputerMockup from '../Assets/Computer-Mockup.png';

const FreeQuoteForm = () => {
  return (
    <div className="relative bg-[#0F101D] text-white py-16">
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between">
        <div className="w-full lg:w-1/2 mb-10 lg:mb-0">
          <h2 className="text-4xl font-bold mb-4">Get A Free Quote</h2>
          <p className="text-lg mb-6">Let's Spark a Conversation!</p>
          <form className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1" htmlFor="name">Enter Your Name <span className="text-red-500">*</span></label>
                <input type="text" id="name" placeholder="Full Name" className="w-full p-3 rounded-lg border border-gray-300 bg-white text-gray-900 focus:outline-none focus:border-yellow-500" />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1" htmlFor="email">Email Address <span className="text-red-500">*</span></label>
                <input type="email" id="email" placeholder="Add Email" className="w-full p-3 rounded-lg border border-gray-300 bg-white text-gray-900 focus:outline-none focus:border-yellow-500" />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="phone">Phone no <span className="text-red-500">*</span></label>
              <input type="text" id="phone" placeholder="Phone No" className="w-full p-3 rounded-lg border border-gray-300 bg-white text-gray-900 focus:outline-none focus:border-yellow-500" />
            </div>
            <button type="submit" className="w-full p-3 bg-yellow-500 text-black font-semibold rounded-lg shadow-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50">Send Message</button>
          </form>
        </div>
        <div className="w-full lg:w-1/2 flex items-center justify-center">
          <img src={ComputerMockup} alt="Laptop and Mobile Mockup" className="w-3/4 lg:w-2/3" />
        </div>
      </div>
    </div>
  );
};

export default FreeQuoteForm;
