import React from 'react';
import Banner from '../components/ContactBanner';
import Section from '../components/ContactSection';

const Contact = () => {
    return (
      <div>
        <Banner />
        <Section />
  
      </div>
    );
  };
  
  export default Contact;