import React from 'react';
import PricingCard from './PricingCard';

const PricingSection = () => {
  const pricingOptions = [
    {
      title: 'Essential',
      price: '$999',
      features: [
        'Up to 5 pages',
        'Responsive design',
        'Stock photos',
        'Contact form',
        'Basic SEO optimization',
        '1 year hosting',
      ],
      buttonLabel: 'Call Now',
    },
    {
      title: 'Standard',
      price: '$1499',
      features: [
        'Up to 10 pages',
        'Custom design elements',
        'Integration with social media',
        'Content management system (CMS) for easy updates',
        'Advanced SEO optimization',
        '1 year hosting',
        '1 year basic maintenance',
      ],
      buttonLabel: 'Call Now',
    },
    {
      title: 'Business',
      price: '$ Custom',
      features: [
        'Unlimited pages',
        'Fully custom design and development',
        'Advanced features (e.g., membership portal, booking system)',
        'Integration with CRM or marketing automation tools',
        'In-depth SEO strategy',
        'E-commerce functionality',
        'Secure hosting',
        'Dedicated project manager',
        'Ongoing maintenance and support',
      ],
      buttonLabel: 'Call Now',
    },
  ];

  return (
    <div className="py-16 bg-white">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {pricingOptions.map((option, index) => (
            <PricingCard
              key={index}
              title={option.title}
              price={option.price}
              features={option.features}
              buttonLabel={option.buttonLabel}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
