import React from 'react';
import Banner from '../components/Mainbanner';
import PromoSection from '../components/PromoSection';
import ServiceCarousel from '../components/ServiceCarousel';
import LandingPageGallery from '../components/LandingPageGallery';
import TestimonialsCarousel from '../components/TestimonialsCarousel';
import FreeQuoteForm from '../components/FreeQuoteForm';
import BrandsCarousel from '../components/BrandsCarousel';

const Home = () => {
  return (
    <div>
      <Banner />
      <PromoSection />
      <ServiceCarousel />
      <LandingPageGallery />
      <TestimonialsCarousel />
      <BrandsCarousel />
      <FreeQuoteForm />

    </div>
  );
};

export default Home;
