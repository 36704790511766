import React from 'react';
import Instagrampost from '../Assets/Web-Development.png'

const WebsiteMaintenanceAbout = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-between bg-white py-8 pl-8">
      <div className="md:w-1/2 pr-8">
        <h2 className="text-2xl font-bold text-black mb-4">
        Keep your website fresh and relevant        </h2>
        <p className="text-base text-gray-600 mb-4">
        Website maintenance is an important part of managing your website. Having a website that is out of date is just as bad as having no website at all, so it’s important to make sure that you try to keep your website’s content up-to-date. Our website maintenance service provides you with easy, cost effective solutions.
        </p>
        <button className="mt-5 bg-yellow-500 text-white py-2 px-4 rounded">
          Read More
        </button>
      </div>
      <div className="mt-8 md:mt-0 md:w-1/2 flex justify-center">
        <img 
          src={Instagrampost} 
          alt="Marketing Strategies" 
          className="w-full h-1/4 object-contain" 
        />
      </div>
    </div>
  );
};

export default WebsiteMaintenanceAbout;
