import React from 'react';
import Banner from './WebHostingBanner';
import About from './WebHostingAbout';
import Section from './WebHostingSec';
import WhyPartnerWithUs from './WebhostingWhyPartnerWithUs';
import TestimonialsCarousel from './TestimonialsCarousel';
import FormSection from './DigitalMarketingFormSection';




const WebHosting = () => {
    return (
      <div>
        <Banner />
        <About />
        <Section />
        <WhyPartnerWithUs />
        <TestimonialsCarousel />
        <FormSection />

      </div>
    );
  };
  
  export default WebHosting;