import React from 'react';
import DigitalMarketing from '../Assets/Website-maintance.png';

const WebDesginDevsection = () => {
    return (
        <div className="bg-white py-12 px-4 sm:px-6 lg:py-16 lg:px-8 flex justify-between items-center space-x-3">


            <div className="ml-10 flex-shrink-0 w-5/12">
                <div className="relative">
                    <img
                        className="h-full w-full object-cover rounded-md"
                        src={DigitalMarketing}
                        alt="Office workspace"
                    />
                </div>
            </div>

            <div className="max-w-3xl w-7/12">

                <h3 className="text-2xl font-bold text-black mb-4">
                Why You Should Update Your Website Frequently
                </h3>
                <p className="text-base text-gray-600 mb-4">
                <strong>Improve SEO Ranking:</strong> Regular updates signal to search engines that your site is active. Enhance SEO with fresh content, new keywords, and optimized images to stay ahead of your competitors in search engine results pages (SERPs).d
                </p>
                <p className="text-base text-gray-600 mb-4">
                <strong>Boost User Experience:</strong> Keep visitors engaged with fresh, relevant content, ensuring your website is easy to navigate with updated design and features. This will improve overall user satisfaction and retention rates.
                </p>
                <p className="text-base text-gray-600 mb-4">
                <strong>Enhance Website Security & Protection:</strong> Protect your website from vulnerabilities by installing the latest security patches and reduce the risk of hacks and data breaches with regular updates. Ensure compliance with the latest security standards and regulations
                </p>
                <p className="text-base text-gray-600 mb-4">
                <strong>Boost Online Presence:</strong> Showcase your latest products, services, and promotions. Provide up-to-date information about your business and keep your audience informed and engaged with the latest news and updates.
                </p>
                <p className="text-base text-gray-600 mb-4">
                <strong>Increase Website Speed:</strong> Optimize your website for speed and responsiveness. Fix bugs and errors that may affect user experience, and enhance overall site functionality and performance.
                </p>
                

            </div>


        </div>
    );
};

export default WebDesginDevsection;
