import React from 'react';
import Banner from './LogoDesignBanner';
import About from './LogoDesignAbout';
import Section from './LogoDesignSec';
import Service from './LogoDesignServices';
import TestimonialsCarousel from './TestimonialsCarousel';
import FormSection from './DigitalMarketingFormSection';




const LogoDesign = () => {
    return (
      <div>
        <Banner />
        <About />
        <Section />
        <Service />
        <TestimonialsCarousel />
        <FormSection />



      </div>
    );
  };
  
  export default LogoDesign;