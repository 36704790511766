import React from 'react';
import Banner from '../components/WebDesignDevBanner';
import WebDesginDivSec from '../components/WebDesginDivSec';
import WebDesginDevsection from '../components/WebDesginDevsection';
import TestimonialsCarousel from '../components/TestimonialsCarousel';
import BrandsCarousel from '../components/BrandsCarousel';


const WebDesignDev = () => {
    return (
      <div>
        <Banner />
        <WebDesginDivSec/>
        <WebDesginDevsection/>
        <TestimonialsCarousel/>
        <BrandsCarousel/>
  
      </div>
    );
  };
  
  export default WebDesignDev;