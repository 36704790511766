import React from 'react';
import videoBackground from '../Assets/contact.mp4'; // Replace with the correct path to your video file

const ContactBanner = () => {
  return (
    <div className="relative w-full h-64 overflow-hidden">
      {/* Background Video */}
      <video 
        className="absolute top-0 left-0 w-full h-full object-cover"
        src={videoBackground} 
        autoPlay 
        loop 
        muted 
      />

      {/* Overlay */}
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-70"></div>

      {/* Content */}
      <div className="relative flex items-center justify-center h-full">
        <h1 className="text-5xl font-bold text-white">Contact Us</h1>
      </div>
    </div>
  );
};

export default ContactBanner;
