import React from 'react';
import Banner from './WebsiteMaintenanceBanner';
import About from './WebsiteMaintenanceAbout';
import Section from './WebsiteMaintenanceSec';
import Services from './WebMaintenanceServices';
import WhyPartnerWithUs from './WebsiteMaintenanceWhyPartnerWithUs';
import TestimonialsCarousel from './TestimonialsCarousel';
import FormSection from './DigitalMarketingFormSection';


const WebHosting = () => {
    return (
      <div>
        <Banner />
        <About />
        <Section />
        <Services    />
        <WhyPartnerWithUs />
        <TestimonialsCarousel />
        <FormSection />

      </div>
    );
  };
  
  export default WebHosting;