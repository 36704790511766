import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const LandingPageCard = ({ image, title, platform,link }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative bg-transparent rounded-lg overflow-hidden transition-transform transform hover:scale-105 duration-300 ease-in-out"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="overflow-hidden h-80 relative">
        <img
          src={image}
          alt={title}
          className={`w-full h-full object-cover transition-transform duration-500 ease-in-out ${
            isHovered ? 'transform -translate-y-2' : 'transform translate-y-0'
          }`}
        />
      </div>
      <div className="p-4 text-center bg-[#0f101d]">
        <h3 className="text-xl font-bold text-white mb-1">{title}</h3>
        <p className="text-sm text-gray-400">{platform}</p>
        <Link className='text-white' to={link}>Visit Website</Link>
      </div>
    </div>
  );
};

export default LandingPageCard;
