import React from 'react';
import DigitalMarketing from '../Assets/DigitalMarketing.jpg';

const WebDesginDevsection = () => {
    return (
        <div className="bg-white py-12 px-4 sm:px-6 lg:py-16 lg:px-8 flex justify-between items-center space-x-3">


            <div className="ml-10 flex-shrink-0 w-5/12">
                <div className="relative">
                    <img
                        className="h-full w-full object-cover rounded-md"
                        src={DigitalMarketing}
                        alt="Office workspace"
                    />
                </div>
            </div>

            <div className="max-w-3xl w-7/12">

                <h3 className="text-2xl font-bold text-black mb-4">
                Digital Marketing Solutions That Deliver Maximum ROI
                </h3>
                <p className="text-base text-gray-600 mb-4">
                In the ever-evolving digital landscape, achieving measurable success requires more than just a presence—it demands effective strategies and precise execution. At Zone Services, we provide digital marketing solutions that deliver tangible results, ensuring your business not only survives but thrives online.
                </p>
                <p className="text-base text-gray-600 mb-4">
                Our comprehensive approach covers every facet of digital marketing. From Search Engine Optimization (SEO) to boost your organic traffic, to Pay-Per-Click (PPC) advertising for immediate visibility, we tailor our strategies to meet your specific business goals. Our social media marketing campaigns build and engage your audience on platforms like Facebook, Instagram, LinkedIn, and Twitter, fostering brand loyalty and driving sales.
                </p>
                

            </div>


        </div>
    );
};

export default WebDesginDevsection;
