import React from 'react';
import LandingPageCard from './LandingPageCard';
import AgamCare from '../Assets/agam.jpg';
import KhalsaPhulwari from '../Assets/kp-scaled.jpg';
import GuruMortgage from '../Assets/guru-scaled.jpg';
import { ClothingP1, HomeRevovation1, Transportation1 } from '../Assets';
import { Link } from 'react-router-dom';

const LandingPageGallery = () => {
  const projects = [

    {
      title: 'Clothing Shop',
      platform: 'Shopify',
      image: ClothingP1,
      link: 'https://westernwhimsey.com/',
    },
    {
      title: 'Transportation',
      platform: 'PhP',
      image: Transportation1,
      link: 'https://www.skyvans.com.au/',
    },
    {
      title: 'Home Renovations',
      platform: 'WordPress',
      image: HomeRevovation1,
      link: 'https://nextlevelprojectsqld.com.au/',
    },

  
  ];

  return (
    <div className="bg-[#0f101d] py-16">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold text-white mb-4">Latest Project</h2>
        <p className="text-white mb-12">
          We build a long-lasting relationship with our clients and nurture it with honesty and content integrity.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <LandingPageCard
              key={index}
              image={project.image}
              title={project.title}
              platform={project.platform}
              link={project.link}
            />
          ))}
        </div>
        <div className="mt-8">
          <Link
            to="/portfolio"
            className="inline-block bg-yellow-500 text-white font-semibold py-3 px-6 rounded-full shadow-md hover:bg-yellow-600"
          >
            VIEW ALL PROJECTS
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandingPageGallery;