import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Import your images
import logo1 from '../Assets/Frame1.png';
import logo2 from '../Assets/Frame2.png';
import logo3 from '../Assets/Frame3.png';
import logo4 from '../Assets/Frame4.png';
import logo5 from '../Assets/Frame5.png';

const BrandsCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container mx-auto py-8">
      <Slider {...settings}>
        <div className="flex justify-center items-center">
          <img src={logo1} alt="Logo 1" className="h-24 w-auto" />
        </div>
        <div className="flex justify-center items-center">
          <img src={logo2} alt="Logo 2" className="h-24 w-auto" />
        </div>
        <div className="flex justify-center items-center">
          <img src={logo3} alt="Logo 3" className="h-24 w-auto" />
        </div>
        <div className="flex justify-center items-center">
          <img src={logo4} alt="Logo 4" className="h-24 w-auto" />
        </div>
        <div className="flex justify-center items-center">
          <img src={logo5} alt="Logo 5" className="h-24 w-auto" />
        </div>
      </Slider>
    </div>
  );
};

export default BrandsCarousel;
