import React from 'react';

const WebMaintenanceServices = () => {
  const services = [
    {
      title: "Assure Regular Updates and Error-Free Performance",
      description: "Ensure your website remains functional and up-to-date with the latest technology. Regular upgrades prevent outdated software issues and ensure your site runs smoothly, providing a seamless experience for your visitors."
    },
    {
      title: "Protect Yourself From Cyber Threats",
      description: "Enhance your website’s security by protecting it from potential threats and vulnerabilities. Our regular updates include installing the latest security patches and measures, reducing the risk of hacks and data breaches, and ensuring your site remains secure and compliant with industry standards."
    },
    {
      title: "Improve Performance and Speed",
      description: "Optimize your website for top speed and peak performance. By addressing performance bottlenecks, fixing bugs, and enhancing overall functionality, we ensure that your site delivers a fast, responsive experience for your users, which is crucial for user satisfaction and search engine rankings."
    },
    {
      title: "Take advantage of the latest features",
      description: "Stay ahead of the competition by incorporating the latest features and design trends. Regular updates allow you to implement new functionalities, improve user experience, and keep your website looking fresh and modern."
    },
  ];

  return (
    <div className="bg-gray-900 text-white py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Our Website Upgradation Services</h2>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {services.map((service, index) => (
            <div key={index} className="bg-gray-800 p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">{service.title}</h3>
              <p className="text-gray-300">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WebMaintenanceServices;
